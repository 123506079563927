import React, { useEffect } from 'react'
import './HubspotForm.scss'

const propertyNames = [
  'Avida Towers Asten Tower 3',
  'Centralis Towers',
  'Avida Towers Ardane',
  'Aldea Grove Estates',
  'Avida Towers Verge',
  'Avida Towers Riala',
  'Southdale Settings NUVALI',
  'girl in the mirror',
]

const HubspotForm = ({ name, onSubmit }: any) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: '4090950',
          formId: 'cd1546d3-7f18-4bcd-93b9-e6bccc0eed98',
          target: '#hubspotForm',
          onFormSubmit: function ($form) {
            var formObj = $form
            var project = formObj[0].elements.project_name_for_project_pages
            project.value = name

            if (propertyNames.includes(name)) {
              gtag('event', 'conversion', {
                send_to: 'AW-10870289884/owOaCNCO4K0DENzrrb8o',
                event_category: 'Form',
                event_label: 'Submission',
              })
            }

            //onSubmit Event Listener
            var formData = $form.serializeArray()
            onSubmit(formData)
          },
        })
      }
    })
  }, [])

  return (
    <div className="form-container">
      <form id="hubspotForm" />
    </div>
  )
}

export default HubspotForm
