import React, { memo, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import { ArticleTypes, PropertyTypes } from './Properties.types'
import { navItems } from './Properties.utils'
import './PropertyDetails.scss'

import { distanceFilter } from '../../services/getDistance'
import CaretRight from '../../assets/svg/caret-right-white.svg'

import HubSpotForm from '../../components/HubspotForm/HubspotForm'

const Seo = loadable(() => import('../../components/Seo/Seo'))

const Layout = loadable(() => import('../../layouts/Layout/Layout'))
const ContentNavigation = loadable(
  () => import('../../components/ContentNavigation/ContentNavigation'),
)
const SideModalDefault = loadable(
  () => import('../../components/SideModalDefault/SideModalDefault'),
)
const InquireNowBtn = loadable(() => import('./component/InquireNowBtn'))

const PropertyBanner = loadable(
  () => import('./component/PropertyBanner/PropertyBanner'),
)
const PropertyOverview = loadable(
  () => import('./component/PropertyOverview/PropertyOverview'),
)

const PropertyConcept = loadable(
  () => import('./component/PropertyConcept/PropertyConcept'),
)
const PropertyLocation = loadable(
  () => import('./component/PropertyLocation/PropertyLocation'),
)
const PropertyAmenities = loadable(
  () => import('./component/PropertyAmenities/PropertyAmenities'),
)
const PropertyUnitsAndFloorPlan = loadable(
  () =>
    import('./component/PropertyUnitsAndFloorPlan/PropertyUnitsAndFloorPlan'),
)
const PropertyVirtualTour = loadable(
  () => import('./component/PropertyVirtualTour/PropertyVirtualTour'),
)
const PropertyGallery = loadable(
  () => import('./component/PropertyGallery/PropertyGallery'),
)
const PropertyNeighborhood = loadable(
  () => import('./component/PropertyNeighborhood/PropertyNeighborhood'),
)
const PropertyUpdates = loadable(
  () => import('./component/PropertyUpdates/PropertyUpdates'),
)
const PropertyNearby = loadable(
  () => import('./component/PropertyNearby/PropertyNearby'),
)

const PropertyInquiry = loadable(
  () => import('./component/PropertyInquiry/PropertyInquiry'),
)

const Properties = ({
  data: {
    propertyData,
    pursuitOfPassionList: { nodes: pursuitOfPassionList },
    allPropertiesList: { nodes: allProperty },
    loanCalculator: { url: loanUrl },
  },
}: PropertyTypes): JSX.Element => {
  const DISTANCE_RADIUS = 5000

  const {
    gallery,
    priceRange,
    propertyConcept,
    propertyLocation,
    propertyLocationSpecific,
    propertyName,
    propertyStatus,
    propertyType,
    locationLandmarks,
    amenities,
    floorPlans = [],
    position,
    logo,
    tags,
    description,
    unitLinkBtn,
    seoOverviewTitle,
    seoOverviewDescription,
    overviewHeadingTag,
    slug,
    // featuredImage,
    // optimizedLogoImg,
    // optimizedFeaturedImg,
  } = propertyData

  const filteredGalleryData =
    gallery && gallery.filter((item) => item.type !== 'panorama')
  const [allNearbyProperties] = useState<any[]>(
    allProperty?.filter(
      (property: any) =>
        distanceFilter({
          position1: position,
          position2: property?.position,
          distance: DISTANCE_RADIUS,
        }) && property.propertyName !== propertyName,
    ),
  )

  const [allSimilarProperties] = useState<any[]>(
    allProperty.filter((property: any): any => {
      const currentPropertyTags = tags?.split(',')
      const isSimilarProperty = property?.tags.split(',')
      if (property.propertyName !== propertyName && property?.tags) {
        return currentPropertyTags?.some((arr) =>
          isSimilarProperty.includes(arr),
        )
      }

      return false
    }),
  )

  const [filteredArticleList] = useState<ArticleTypes[]>(
    pursuitOfPassionList?.filter((featured: ArticleTypes) => {
      const currentPropertyTags = tags?.split(',')

      return (
        distanceFilter({
          position1: position,
          position2: featured?.position,
          distance: DISTANCE_RADIUS,
        }) || currentPropertyTags?.some((arr) => featured?.tags.includes(arr))
      )
    }),
  )

  const [articleList] = useState<ArticleTypes[]>(filteredArticleList)

  const [neighborhoodList] = useState(articleList)
  const [neighborhoodListLimit, setNeighborhoodListLimit] = useState(3)
  const [nearbyProperties] = useState([
    ...allNearbyProperties,
    ...allSimilarProperties,
  ])
  const [nearbyPropertiesCards, setNearbyPropertiesCards] = useState(3)
  const [currentFloorPlanImg, setCurrentFloorPlanImg] = useState(0)
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [showAmenitiesModal, setShowAmenitiesModal] = useState(false)
  const [showGallery, setShowGallery] = useState(false)
  const [currentAmenityItem, setCurrentAmenityItem] = useState(0)
  const [currentGalleryItem, setCurrentGalleryItem] = useState(0)
  const [showUpdatesModal, setShowUpdatesModal] = useState(false)
  const [currentVirtualTour, setCurrentVirtualTour] = useState(0)

  const handleShowAmenities = (): void => {
    setShowAmenitiesModal((prev) => !prev)
  }

  const handleShowGallery = (): void => {
    setShowGallery((prev) => !prev)
  }

  const handleShowSideMenu = (): void => {
    setShowSideMenu((prev) => !prev)
  }

  const handleShowUpdatesModal = (): void => {
    setShowUpdatesModal((prev) => !prev)
  }

  const showMoreNeighborhoodList = (): void => {
    if (neighborhoodListLimit === 3) {
      setNeighborhoodListLimit(neighborhoodList.length)
    } else {
      setNeighborhoodListLimit(3)
    }
  }

  const showNearbyProperties = (): void => {
    if (nearbyPropertiesCards === 3) {
      setNearbyPropertiesCards(nearbyProperties.length)
    } else {
      setNearbyPropertiesCards(3)
    }
  }

  console.log('propertyType', propertyType?.toLowerCase())

  return (
    <>
      <Seo
        title={seoOverviewTitle || `AVIDA Property - ${propertyName}`}
        ogMetaData={{
          title: seoOverviewTitle || `AVIDA Property - ${propertyName}`,
          description:
            seoOverviewDescription || `${propertyConcept?.description}`,
          image: '',
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/properties/${propertyType?.toLowerCase().replace(/\s+/g, '-')}/${slug}/`,
          keywords: `properties, condo, philippines, house and lot, avidaland, avida, apartment, contact us, avida property, ${seoOverviewTitle}`,
        }}
      />
      <Layout
        navButtonVariant="back"
        returnPageUrl="/properties/"
        includeNewsLetter={false}
      >
        <div className="property-details">
          <ContentNavigation
            navigations={navItems}
            center
            className="property-details__nav"
            isTypeScroll
          />

          <PropertyBanner
            propertyName={propertyName}
            bannerTagLine={description}
            slides={gallery}
          />

          <PropertyOverview
            propertyLocation={propertyLocation}
            propertyName={propertyName}
            propertyStatus={propertyStatus}
            propertyType={propertyType}
            priceRange={priceRange}
            fn={handleShowSideMenu}
            logo={logo}
          />
          <div className="property-details__main-content">
            <PropertyConcept
              title={propertyConcept?.title}
              description={propertyConcept?.description}
            />
            <PropertyLocation
              propertyName={propertyName}
              location={propertyLocation}
              position={position}
              specificLocation={propertyLocationSpecific}
              landmark={locationLandmarks}
              logo={logo}
            />

            <PropertyAmenities
              amenityList={amenities?.amenityList}
              title={amenities?.title}
              description={amenities?.description}
              showAmenitiesModal={showAmenitiesModal}
              handleShowAmenities={handleShowAmenities}
              modalClass="gallery-modal"
              setShowAmenitiesModal={setShowAmenitiesModal}
              currentAmenityItem={currentAmenityItem}
              setCurrentAmenityItem={setCurrentAmenityItem}
            />

            <PropertyUnitsAndFloorPlan
              floorPlans={floorPlans}
              floorPlanImg={currentFloorPlanImg}
              setFloorPlanImg={setCurrentFloorPlanImg}
            />

            <PropertyVirtualTour
              gallery={gallery}
              currentVirtualTour={currentVirtualTour}
              setCurrentVirtualTour={setCurrentVirtualTour}
            />

            <PropertyGallery
              gallery={filteredGalleryData}
              handleShowGallery={handleShowGallery}
              showGallery={showGallery}
              setShowGallery={setShowGallery}
              modalClass="gallery-modal"
              currentGalleryItem={currentGalleryItem}
              setCurrentGalleryItem={setCurrentGalleryItem}
            />
            <PropertyNeighborhood
              neighborhoodList={neighborhoodList}
              neighborhoodLocation={propertyLocation}
              neighborhoodLocationSpecific={propertyLocationSpecific}
              itemsToDisplay={neighborhoodListLimit}
              displayMore={showMoreNeighborhoodList}
            />
            <PropertyUpdates
              towers={propertyData?.constructionUpdates?.towers}
              gallery={propertyData?.constructionUpdates?.gallery}
              showUpdatesModal={showUpdatesModal}
              handleShowUpdatesModal={handleShowUpdatesModal}
              setShowUpdatesModal={setShowUpdatesModal}
              modalClass="gallery-modal"
            />
            <PropertyNearby
              nearbyProperties={nearbyProperties}
              showNearbyProperties={showNearbyProperties}
              propertiesCardsToDisplay={nearbyPropertiesCards}
            />
          </div>

          <PropertyInquiry
            unitLinkBtn={unitLinkBtn}
            fn={handleShowSideMenu}
            loanCalculator={loanUrl}
          />

          {!!propertyData && showSideMenu && (
            <SideModalDefault onClose={handleShowSideMenu}>
              <div className="modal">
                <h4>INQUIRE NOW</h4>
                <p className="modal-description">
                  Complete the form below for a simple and seamless homebuying
                  experience
                </p>
                <p className="modal-project">Project: {propertyName}</p>
                <HubSpotForm name={propertyName} />
              </div>
            </SideModalDefault>
          )}
        </div>
      </Layout>
      <InquireNowBtn btnClass="inquire-now-btn--sticky" fn={handleShowSideMenu}>
        <span>Inquire Now</span> <img src={CaretRight} alt="caret right" />
      </InquireNowBtn>
    </>
  )
}

export default memo(Properties)

export const pageQuery = graphql`
  query PropertyQuery($id: String!) {
    propertyData: property(id: { eq: $id }) {
      ...CombinedPropertyFields
    }
    pursuitOfPassionList: allPursuitOfPassion {
      nodes {
        ...OurNeighborhoodPursuitOfPassionCardFields
      }
    }
    allPropertiesList: allProperty(
      filter: { propertyName: { ne: "home_page_banner" } }
    ) {
      nodes {
        ...NearbyAndSimilarProperties
      }
    }
    loanCalculator: link(
      category: { eq: "external" }
      name: { eq: "loan_calculator" }
    ) {
      ...LinkFields
    }
  }
`
