import { navItemsType } from './Properties.types'

export const imgFallback = ({ currentTarget }: any): void => {
  const imageFallback = currentTarget
  imageFallback.onerror = null
  imageFallback.src = `https://placehold.co/600x450/EEE/FFFFFF?font=open-sans&text=Property%20Img`
}
export const navItems: navItemsType[] = [
  { label: 'Concept', url: 'property-concept' },
  { label: 'Location', url: 'property-location' },
  { label: 'Amenities', url: 'property-amenities' },
  { label: 'Units & Floor Plans', url: 'property-units-and-floor-plans' },
  { label: 'Virtual Tour', url: 'property-virtual-tour' },
  { label: 'Gallery', url: 'property-gallery' },
  { label: 'Our Neighborhood', url: 'property-our-neighborhood' },
  { label: 'Updates', url: 'property-updates-section' },
]

export default {}
